import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from 'axios';

import mail from '../../assets/icons/email-1.png';
import phone from '../../assets/icons/phone.png';
import whatsapp from '../../assets/icons/whatsapp.png';

const About = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const submit = (e) => {
        e.preventDefault()
        axios(`https://hooks.zapier.com/hooks/catch/9922277/ovo9yiz`, {
          method: 'POST',
          body: JSON.stringify({ name: name, email: email, message: message }),
        })

        axios.post("https://hooks.zapier.com/hooks/catch/9922277/ovo9yiz", JSON.stringify({ name: name, email: email, message: message }))
        .then(res => document.getElementById('id02').style.display='block')      
        .catch(err => document.getElementById('id03').style.display='block');
    }

    return(
        <>
            <div id="contact">
                <Container className="" style={{maxWidth: '90%'}}>
                    <Row>
                        <Col className="pr-5" xs={12} md={6}>
                            <div>
                                <p className="contact-title">Contact Us</p>
                                <p className="contact-text right" style={{marginLeft: 'auto'}}>
                                    Feel free top get in touch with us. We are always open to discussing
                                    new projects, creative ideas or opportunities to be part of your
                                    visions.
                                </p>
                                <p className="contact-text right">
                                    <img src={mail} alt="email" width={50}/><br/>
                                    <b>Mail me</b> <br/>
                                    <a className="link" href="mailto:epdawu@gmail.com">epdawu@gmail.com</a> 
                                </p>
                                <p className="contact-text right">
                                    <img src={phone} alt="phone" width={50}/><br/>
                                    <b>Call us</b> <br/>
                                    <a className="link" href="tel:+268 7611 2580">+268 7611 2580</a> <br/>
                                    <a className="link" href="tel:+268 7623 5598">+268 7623 5598</a> <br/>
                                    <a className="link" href="tel:+268 7655 4583">+268 7655 4583</a>  
                                </p>
                                <p className="contact-text right">
                                    <img src={whatsapp} alt="whatsapp" width={50}/><br/>
                                    <b>WhatsApp</b> <br/>
                                    <a className="link" href="https://chatwith.io/s/epdawu">+268 7814 0559</a>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <form>
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder="Enter your name" 
                                    onChange={(e)=>{setName(e.target.value)}}
                                />
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Enter a valid email address" 
                                    onChange={(e)=>{setEmail(e.target.value)}}
                                />
                                <textarea 
                                    name="message" 
                                    rows="4" 
                                    placeholder="Enter your message"
                                    onChange={(e)=>{setMessage(e.target.value)}}
                                />
                                <button 
                                    type="submit" 
                                    onClick={(e)=>{submit(e)}}
                                >
                                    SUBMIT
                                </button>
                            </form>
                            <div className="boxElement"/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="events-sec" />
        </>
    );
}

export default About;