import React from "react";
import "../App.css";
import Header from "../components/Header";
import About from "../components/About";
import Contact from "../components/Contact";
import Events from "../components/Events";

function Home() {
    return (
      <>
        <Header/>
        <About/>
        <Contact/>
        <Events/>
      </>
    );
}

export  default Home;