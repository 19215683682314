import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import logo from '../../assets/logos/logo.png';
import constitution from "../../assets/icons/constitution-1.png";
import agreement from "../../assets/icons/agreement-1.png";
import form1 from "../../assets/icons/form-1.png";
import policies from "../../assets/icons/policies-1.png";

import form1Doc from "../../assets/docs/MEMBERSHIP-REGISTRATION-FORM.pdf";
import constitutionDoc from "../../assets/docs/Contsitution-Swaziland_Property_Developers_&_Allied_Workers_Union.pdf";
import agreementDoc from "../../assets/docs/Signed-SPDAWU-Recognition.pdf";



const Header = () => {
    const [home, setHome] = useState(true);
    const [about, setAbout] = useState(false);
    const [contact, setContact] = useState(false);
    // const [marketing, setMarketing] = useState(false);
    const [events, setEvents] = useState(false);

    useEffect(() => {
        window.onscroll = function() {myFunction()};

        var navbar = document.getElementById("navbar");
        var sticky = navbar.offsetTop;

        var homeSection = document.getElementById("home");
        var aboutSection = document.getElementById("about-sec");
        var contactSection = document.getElementById("contact-sec");
        // var marketingSection = document.getElementById("marketing-sec");
        var eventsSection = document.getElementById("events-sec");

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }

            if(window.pageYOffset >= homeSection.offsetTop){
                setAllToFalse();
                setHome(true);
            }
            if(window.pageYOffset >= aboutSection.offsetTop){
                setAllToFalse();
                setAbout(true);
            }
            if(window.pageYOffset >= contactSection.offsetTop){
                setAllToFalse();
                setContact(true);
            }
            // if(window.pageYOffset === marketingSection.offsetTop){
            //     setAllToFalse();
            //     setMarketing(true);
            // }
            if(window.pageYOffset >= eventsSection.offsetTop){
                setAllToFalse();
                setEvents(true);
            }

        }
    },[]);

    function setAllToFalse(){
        setHome(false);
        setAbout(false);
        setContact(false);
        // setMarketing(false);
        setEvents(false);
    }

    return(
        <>
            <div id="home">
                <div className="header">
                    {/* <h2>Scroll Down</h2>
                    <p>Scroll down to see the sticky effect.</p> */}
                    <img src={logo} alt="logo" className="logo"/>
                </div>

                <div id="navbar">
                    <div className="nav-container">
                    <a className={home === true ? "active" : ""} onClick={() => {setAllToFalse(); setHome(true);}} href="#home">HOME</a>
                    <a className={about === true ? "active" : ""} onClick={() => {setAllToFalse(); setAbout(true);}} href="#about-sec">ABOUT US</a>
                    <a className={contact === true ? "active" : ""} onClick={() => {setAllToFalse(); setContact(true);}} href="#contact-sec">CONTACT US</a>
                    {/* <a className={marketing === true ? "active" : ""} onClick={() => {setAllToFalse(); setMarketing(true);}} href="#marketing-sec">MARKETING</a> */}
                    <a className={events === true ? "active" : ""} onClick={() => {setAllToFalse(); setEvents(true);}} href="#events-sec">EVENTS</a>
                    </div>
                </div>

                <div className="content">
                    <p className="title">Creating a Better Future For You</p>
                    <p className="sub-title">
                    The Eswatini Property Developers and Allied Workers Union (EPDAWU)
                    aims to advance and defend the interest of workers in respect
                    of all matters of mutual interest between workers and their
                    employers.
                    <br/><br/>
                    EPDAWU is an affiliate of FESWATU. We intend TO represent
                    workers in fixed and indefinite contracts of employment in all
                    categories of work under construction or property developers.
                    </p>

                    <Container className="mt-5">
                        <Row>
                            <Col xs={6} md={3}>
                                <a href={form1Doc} target="_blank" rel="noreferrer">
                                    <div className="home-tab">
                                        <img src={form1} alt="form" className="home-icon"/>
                                        Application Form
                                    </div>
                                </a>
                            </Col>
                            <Col xs={6} md={3}>
                                <button className="invisible-button" onClick={() => document.getElementById('id01').style.display='block'}>
                                    <div className="home-tab">
                                        <img src={policies} alt="policies" className="home-icon"/>
                                        Policies
                                    </div>
                                </button>
                            </Col>
                            <Col xs={6} md={3}>
                                <a href={agreementDoc} target="_blank" rel="noreferrer">
                                    <div className="home-tab">
                                        <img src={agreement} alt="agreement" className="home-icon"/>
                                        Recognition Agreement
                                    </div>
                                </a>
                            </Col>
                            <Col xs={6} md={3}>
                                <a href={constitutionDoc} target="_blank" rel="noreferrer">
                                    <div className="home-tab">
                                        <img src={constitution} alt="logo" className="home-icon"/>
                                        Constitution
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    
                </div>
            </div>
            <div id="about-sec"/>
        </>
    );
}

export default Header;