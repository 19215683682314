import './App.css';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/home";

function App() {
  return (
   <Router>
    <Switch>
      <Route path="/" exact component={Home} />
    </Switch>

   </Router> 
  );
}

export default App;
