import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const About = () => {

    return(
        <>
            <div id="about">
                <svg className="curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#fff" fillOpacity="1" d="M0,224L120,202.7C240,181,480,139,720,144C960,149,1200,203,1320,229.3L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                </svg>
                <Container className="about-container">
                    <Row>
                        <Col xs={12} md={3}>
                            <div>
                                <p className="text center">EPDAWU Establishment</p>
                                <p className="text">
                                    This Union was established
                                    in November 2014 and attained
                                    certification from the Ministry
                                    of Labour and social security
                                    on the 26th June 2015.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div>
                                <p className="text center">Vision</p>
                                <p className="text">
                                    We are going to enhance
                                    the economy of Eswatini
                                    through hard work and through friendly,
                                    enjoyable and healthy
                                    relationships between
                                    employer and employee.
                                    We will also provide
                                    employment for our people
                                    and stability for our business,
                                    our government and our King,
                                    Ingwenyama.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div>
                                <p className="text center">Mission</p>
                                <p className="text">
                                    We are working towards
                                    creating better employment
                                    opportunities in Eswatini so
                                    that the free market forces of
                                    strong demand replace strike
                                    action driving wage increase
                                    and enable our people who
                                    have been forced to seek
                                    employment outside Eswatini
                                    to return home to be with their
                                    families and serve their country.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div>
                                <p className="text center">Aim</p>
                                <p className="text">
                                    As a Union we aim to
                                    advance and defend
                                    the interest of workers
                                    in respect of all matters
                                    of mutual interest
                                    between workers and
                                    their employers. We
                                    also aim to secure fair
                                    terms and conditions of
                                    employment and enforce
                                    fair labour practices.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="contact-sec" />
        </>
    );
}

export default About;