import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Image1 from '../../assets/images/e-1.jpg';
import Image2 from '../../assets/images/e-2.jpg';
import Image3 from '../../assets/images/e-3.jpg';
import Image4 from '../../assets/images/e-4.jpg';
import Image5 from '../../assets/images/e-5.jpg';
import Image6 from '../../assets/images/e-6.jpg';
import Image7 from '../../assets/images/e-7.jpg';

const Events = () => {

    return(
        <div id="events">
            <svg className="curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#fff" fillOpacity="1" d="M0,224L120,202.7C240,181,480,139,720,144C960,149,1200,203,1320,229.3L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
            </svg>
            <Container className="events-container">
                <Row className="mb-5">
                    <Col xs={12} md={6}>
                        <div>
                            <p className="text center">Trainings: Grievance &amp; Discipline Handling</p>
                            <p className="text">
                            On the 15th to 17th September 2016, the Human resources department
                            held a three day workshop facilitated by the Federation of Swaziland
                            Employers and Chamber of Commerce (FSE&amp;CC).
                            <br/><br/>
                            Participants of the workshop, included Supervisors and Union Representatives.
                            The primary objective of the workshop was to emphasise the importance of
                            handling grievances appropiately. The training equiped participants with the
                            essential skills of handling grievances and descipline in the workplace as well
                            as conducting fair appeal hearings.
                            <br/><br/>
                            The workshop was presented by Mr. Sipho Nyoni (Commisioner: Hhohho region)
                            from CMAC.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div>
                            <img src={Image1} alt="" className="events-img"/>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs={12} md={4}>
                        <div>
                            <div className="events-img-container">
                                <img src={Image2} alt="" className="events-img"/>
                            </div>
                            <p className="text center">
                                Some of EPDAWU members posing
                                for agroup photo during Boleswana
                                games.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div>
                            <div className="events-img-container">
                                <img src={Image3} alt="" className="events-img"/>
                            </div>
                            <p className="text center">
                                FESWATU affiliates attending
                                a meeting in preparation for
                                May day.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div>
                            <div className="events-img-container">
                                <img src={Image4} alt="" className="events-img"/>
                            </div>
                            <p className="text center">
                                ILO Director and the National Executive
                                of FESWATU posing for a group photo
                                after a meeting.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={4}>
                        <div>
                            <img src={Image5} alt="" className="events-img"/>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div>
                            <img src={Image6} alt="" className="events-img"/>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div>
                            <img src={Image7} alt="" className="events-img"/>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs={12}>
                        <p className="text center">
                            Some of EPDAWU members posing for a group photo at the maintainance office.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Events;